import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import PageContainer from "../../components/container/PageContainer";
import img1 from "../../assets/images/backgrounds/login_bg.jpg";
import LogoIcon from "../../layouts/full-layout/logo/LogoIcon";
import { Button } from "@mui/material";
import { useNavigate } from "react-router";

export default function Terms() {
  const navigate = useNavigate();

  return (
    <PageContainer title="TERMS OF USE" description="this is TERMS OF USE page">
      <Grid
        container
        spacing={0}
        sx={{
          // backgroundImage: `url(${img1})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          display: "flex",
          backgroundPosition: "center",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Grid
          sx={{
            background: "rgba( 255, 255, 255, 0.45 )",
            boxShadow: "0 8px 32px 0 rgba( 31, 38, 135, 0.37 )",
            backdropFilter: "blur( 10px )",
            height: "90%",
            width: "90%",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "10px",
            marginY: "5%",
          }}
        >
          <Box
            sx={{
              margin: "2%",
            }}
          >
            <Typography
              variant="h1"
              sx={{ textAlign: "center", mb: 5, color: "#441F00" }}
            >
              TERMS OF USE
            </Typography>

            <Box sx={{ margin: "2%" }}>
              <Typography variant="h6" sx={{ textAlign: "center" }}>
                GENERAL
              </Typography>

              <Typography variant="p" sx={{ textAlign: "center" }}>
                These Terms of Use ("Terms") govern your use of the INARA SKIN
                mobile application ("App"). By accessing or using the App, you
                agree to these Terms and our Privacy Policy.
              </Typography>
            </Box>

            <Box sx={{ margin: "2%" }}>
              <Typography variant="h6" sx={{ textAlign: "center" }}>
                DEFINITIONS
              </Typography>
              <Typography variant="p" sx={{ textAlign: "center" }}>
                - User: Any person or entity accessing or using the App. - We,
                Us, Our: INARA SKIN and its representatives. - Parties: User and
                INARA SKIN collectively.
              </Typography>
            </Box>
            <Box sx={{ margin: "2%" }}>
              <Typography variant="h6" sx={{ textAlign: "center" }}>
                ABOUT INARA SKIN
              </Typography>
              <Typography variant="p" sx={{ textAlign: "center" }}>
                INARA SKIN is a mobile application that utilizes AI technology
                to detect skin conditions and recommend personalized skincare
                products. Our team of certified dermatologists ensures the
                accuracy and reliability of the recommendations.
              </Typography>
            </Box>
            <Box sx={{ margin: "2%" }}>
              <Typography variant="h6" sx={{ textAlign: "center" }}>
                OUR SERVICES
              </Typography>
              <Typography variant="p" sx={{ textAlign: "center" }}>
                INARA SKIN offers: - Skin Analysis: Using AI to detect skin
                problems from uploaded face images. - Personalized
                Recommendations: Suggesting skincare products based on detected
                skin conditions and user-provided skin attributes. -
                Consultations: Access to certified dermatologists for expert
                advice.
              </Typography>
            </Box>
            <Box sx={{ margin: "2%" }}>
              <Typography variant="h6" sx={{ textAlign: "center" }}>
                USER RESPONSIBILITIES
              </Typography>
              <Typography variant="p" sx={{ textAlign: "center" }}>
                Users must provide accurate information and comply with all
                instructions for using the App. Users are responsible for
                maintaining the confidentiality of their account and password.
              </Typography>
            </Box>
            <Box sx={{ margin: "2%" }}>
              <Typography variant="h6" sx={{ textAlign: "center" }}>
                TERMINATION
              </Typography>
              <Typography variant="p" sx={{ textAlign: "center" }}>
                INARA SKIN reserves the right to terminate user access to the
                App without notice for any violation of these Terms.
              </Typography>
            </Box>
            <Box sx={{ margin: "2%" }}>
              <Typography variant="h6" sx={{ textAlign: "center" }}>
                INDEMNITY
              </Typography>
              <Typography variant="p" sx={{ textAlign: "center" }}>
                Users agree to indemnify and hold INARA SKIN harmless from any
                claims arising from their use of the App, including any breach
                of these Terms.
              </Typography>
            </Box>
            <Box sx={{ margin: "2%" }}>
              <Typography variant="h6" sx={{ textAlign: "center" }}>
                INTELLECTUAL PROPERTY
              </Typography>
              <Typography variant="p" sx={{ textAlign: "center" }}>
                All content on the App is the property of INARA SKIN. Users may
                not reproduce, distribute, or create derivative works from the
                App content without prior written permission.
              </Typography>
            </Box>
            <Box sx={{ margin: "2%" }}>
              <Typography variant="h6" sx={{ textAlign: "center" }}>
                DISCLAIMER OF WARRANTIES
              </Typography>
              <Typography variant="p" sx={{ textAlign: "center" }}>
                The App is provided "as is" without any warranties. INARA SKIN
                does not guarantee uninterrupted or error-free operation of the
                App.
              </Typography>
            </Box>

            <Box sx={{ margin: "2%" }}>
              <Typography variant="h6" sx={{ textAlign: "center" }}>
                DISPUTE RESOLUTION AND JURISDICTION
              </Typography>
              <Typography variant="p" sx={{ textAlign: "center" }}>
                Disputes will be resolved through mediation or arbitration under
                Indian law, with jurisdiction in New Delhi.
              </Typography>
            </Box>

            <Box sx={{ margin: "2%" }}>
              <Typography variant="h6" sx={{ textAlign: "center" }}>
                MISCELLANEOUS PROVISIONS
              </Typography>
              <Typography variant="p" sx={{ textAlign: "center" }}>
                These Terms constitute the entire agreement between the User and
                INARA SKIN. Any waiver of rights must be in writing, and
                severability of terms applies.
              </Typography>
            </Box>

            {/* <Box textAlign="center">
              <Button
                color="secondary"
                variant="contained"
                onClick={() => navigate("/")}
                size="large"
                type="submit"
                sx={{
                  mt: "10px",
                  pt: "10px",
                  pb: "10px",
                  borderRadius: "10px",
                  textTransform: "uppercase",
                  background:
                    "linear-gradient(90deg, rgba(139,110,65,1) 0%, rgba(183,153,107,1) 50%, rgba(139,110,65,1) 100%)",
                }}
              >
                Back
              </Button>
            </Box> */}
          </Box>
        </Grid>
      </Grid>
    </PageContainer>
  );
}
